import { type Analytic } from 'types/analytic';
import { type Pagination } from 'types/pagination';
import { getRequest } from 'utils/rest-api-util';
import { StringUtil } from 'utils/string-util';

export const getAnalytics = async (
  page?: number,
): Promise<AnalyticsResponse> => {
  const searchParams = new URLSearchParams();
  if (page != null) {
    searchParams.append('page', page.toString());
  }
  const response = await getRequest(`/api/v1/analytics`, searchParams);

  return StringUtil.snakeObjectToCamelObject(
    await response.json(),
  ) as AnalyticsResponse;
};

export type AnalyticsResponse = {
  analytics: Analytic[];
  pagination: Pagination;
};
